import { useState } from 'react';

import BranchContainer from './BranchContainer';
import AdminHeader from '../../layout/adminheader/AdminHeader';
import PageHeader from '../../layout/pageHeader/PageHeader';
import ShopBranchTable from '../../tables/ShopBranchTable';

const ShopBranches = () => {
  const [menuOptionIcon, setMenuOptionIcon] = useState(false);

  const toggleIconClickHandler = () => {
    setMenuOptionIcon(!menuOptionIcon);
  };

  return (
    <div className="middleData">
      <AdminHeader title="ABC Barber Shop" />
      <div className="DBdataGridBox">
        <PageHeader
          heading="Branch List"
          menuOptionIcon={menuOptionIcon}
          toggleIconClickHandler={toggleIconClickHandler}
        />
      </div>
      {menuOptionIcon ? <BranchContainer /> : <ShopBranchTable />}
    </div>
  );
};

export default ShopBranches;
