export const artistsList = [
  {
    id: '1',
    name: 'Ram Kumar',
    work: 'Hair Stylist',
    experience: '2 years',
    email: 'abc@gmail.com',
    phone: '9876543210',
    address: 'Vaishali Nagar, Jaipur',
    rating: 2.5
  },
  {
    id: '2',
    name: 'Ram Kumar',
    work: 'Hair Stylist',
    experience: '2 years',
    email: 'abc@gmail.com',
    phone: '9876543210',
    address: 'Vaishali Nagar, Jaipur',
    rating: 2.5
  },
  {
    id: '3',
    name: 'Ram Kumar',
    work: 'Hair Stylist',
    experience: '2 years',
    email: 'abc@gmail.com',
    phone: '9876543210',
    address: 'Vaishali Nagar, Jaipur',
    rating: 2.5
  },
  {
    id: '4',
    name: 'Ram Kumar',
    work: 'Hair Stylist',
    experience: '2 years',
    email: 'abc@gmail.com',
    phone: '9876543210',
    address: 'Vaishali Nagar, Jaipur',
    rating: 2.5
  },
  {
    id: '5',
    name: 'Ram Kumar',
    work: 'Hair Stylist',
    experience: '2 years',
    email: 'abc@gmail.com',
    phone: '9876543210',
    address: 'Vaishali Nagar, Jaipur',
    rating: 2.5
  },
  {
    id: '6',
    name: 'Ram Kumar',
    work: 'Hair Stylist',
    experience: '2 years',
    email: 'abc@gmail.com',
    phone: '9876543210',
    address: 'Vaishali Nagar, Jaipur',
    rating: 2.5
  },
  {
    id: '7',
    name: 'Ram Kumar',
    work: 'Hair Stylist',
    experience: '2 years',
    email: 'abc@gmail.com',
    phone: '9876543210',
    address: 'Vaishali Nagar, Jaipur',
    rating: 2.5
  },
  {
    id: '8',
    name: 'Ram Kumar',
    work: 'Hair Stylist',
    experience: '2 years',
    email: 'abc@gmail.com',
    phone: '9876543210',
    address: 'Vaishali Nagar, Jaipur',
    rating: 2.5
  }
];

export const artistsColumnName = [
  {
    id: '1',
    name: 'Sr.No.'
  },
  {
    id: '2',
    name: 'Artists Name'
  },
  {
    id: '3',
    name: 'Work'
  },
  {
    id: '4',
    name: 'Experience'
  },
  {
    id: '5',
    name: 'Email Address'
  },
  {
    id: '6',
    name: 'Phone Number'
  },
  {
    id: '7',
    name: 'Address'
  },
  {
    id: '8',
    name: 'Overall Rating'
  }
];
