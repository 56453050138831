import './PageHeader.scss';
import MenuSVGIcon from '../../assets/svgIcons/MenuSVGIcon/MenuSVGIcon';
import TogglerSVGIcon from '../../assets/svgIcons/TogglerSVGIcon/TogglerSVGIcon';

function PageHeader({ heading, menuOptionIcon, toggleIconClickHandler }) {
  return (
    <div className="pageHeader">
      <div className="pageHeaderRight">
        {heading && <div className="pageHeaderHeading">{heading}</div>}
        <div className="inputBox">
          <input type="search" placeholder="Search" id="" name="" />
          <span className="inputIcon">
            <i className="bi bi-search"></i>
          </span>
        </div>
        <button className="adminMainBtn" onClick={onclick}>
          <div className="filterBox">
            <span className="filterIcon">
              <i className="bi bi-funnel"></i>
            </span>
          </div>
        </button>
      </div>

      <div className="pageHeaderLeft">
        <p>
          Showing <span>16</span> from <span>893</span> results
        </p>
        {heading === 'Branch List' && (
          <span onClick={toggleIconClickHandler}>
            {menuOptionIcon ? <MenuSVGIcon /> : <TogglerSVGIcon />}
          </span>
        )}
      </div>
    </div>
  );
}

export default PageHeader;
