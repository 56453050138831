const CategorySVGIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.12">
        <path
          d="M4.6 1H2.06667C1.6933 1 1.50661 1 1.36401 1.07266C1.23856 1.13658 1.13658 1.23856 1.07266 1.36401C1 1.50661 1 1.6933 1 2.06667V4.6C1 4.97337 1 5.16005 1.07266 5.30266C1.13658 5.4281 1.23856 5.53009 1.36401 5.594C1.50661 5.66667 1.6933 5.66667 2.06667 5.66667H4.6C4.97337 5.66667 5.16005 5.66667 5.30266 5.594C5.4281 5.53009 5.53009 5.4281 5.594 5.30266C5.66667 5.16005 5.66667 4.97337 5.66667 4.6V2.06667C5.66667 1.6933 5.66667 1.50661 5.594 1.36401C5.53009 1.23856 5.4281 1.13658 5.30266 1.07266C5.16005 1 4.97337 1 4.6 1Z"
          fill="#3B7358"
        />
        <path
          d="M11.9333 1H9.4C9.02663 1 8.83995 1 8.69734 1.07266C8.5719 1.13658 8.46991 1.23856 8.406 1.36401C8.33333 1.50661 8.33333 1.6933 8.33333 2.06667V4.6C8.33333 4.97337 8.33333 5.16005 8.406 5.30266C8.46991 5.4281 8.5719 5.53009 8.69734 5.594C8.83995 5.66667 9.02663 5.66667 9.4 5.66667H11.9333C12.3067 5.66667 12.4934 5.66667 12.636 5.594C12.7614 5.53009 12.8634 5.4281 12.9273 5.30266C13 5.16005 13 4.97337 13 4.6V2.06667C13 1.6933 13 1.50661 12.9273 1.36401C12.8634 1.23856 12.7614 1.13658 12.636 1.07266C12.4934 1 12.3067 1 11.9333 1Z"
          fill="#3B7358"
        />
        <path
          d="M11.9333 8.33333H9.4C9.02663 8.33333 8.83995 8.33333 8.69734 8.406C8.5719 8.46991 8.46991 8.5719 8.406 8.69734C8.33333 8.83995 8.33333 9.02663 8.33333 9.4V11.9333C8.33333 12.3067 8.33333 12.4934 8.406 12.636C8.46991 12.7614 8.5719 12.8634 8.69734 12.9273C8.83995 13 9.02663 13 9.4 13H11.9333C12.3067 13 12.4934 13 12.636 12.9273C12.7614 12.8634 12.8634 12.7614 12.9273 12.636C13 12.4934 13 12.3067 13 11.9333V9.4C13 9.02663 13 8.83995 12.9273 8.69734C12.8634 8.5719 12.7614 8.46991 12.636 8.406C12.4934 8.33333 12.3067 8.33333 11.9333 8.33333Z"
          fill="#3B7358"
        />
        <path
          d="M4.6 8.33333H2.06667C1.6933 8.33333 1.50661 8.33333 1.36401 8.406C1.23856 8.46991 1.13658 8.5719 1.07266 8.69734C1 8.83995 1 9.02663 1 9.4V11.9333C1 12.3067 1 12.4934 1.07266 12.636C1.13658 12.7614 1.23856 12.8634 1.36401 12.9273C1.50661 13 1.6933 13 2.06667 13H4.6C4.97337 13 5.16005 13 5.30266 12.9273C5.4281 12.8634 5.53009 12.7614 5.594 12.636C5.66667 12.4934 5.66667 12.3067 5.66667 11.9333V9.4C5.66667 9.02663 5.66667 8.83995 5.594 8.69734C5.53009 8.5719 5.4281 8.46991 5.30266 8.406C5.16005 8.33333 4.97337 8.33333 4.6 8.33333Z"
          fill="#3B7358"
        />
      </g>
      <path
        d="M4.6 1H2.06667C1.6933 1 1.50661 1 1.36401 1.07266C1.23856 1.13658 1.13658 1.23856 1.07266 1.36401C1 1.50661 1 1.6933 1 2.06667V4.6C1 4.97337 1 5.16005 1.07266 5.30266C1.13658 5.4281 1.23856 5.53009 1.36401 5.594C1.50661 5.66667 1.6933 5.66667 2.06667 5.66667H4.6C4.97337 5.66667 5.16005 5.66667 5.30266 5.594C5.4281 5.53009 5.53009 5.4281 5.594 5.30266C5.66667 5.16005 5.66667 4.97337 5.66667 4.6V2.06667C5.66667 1.6933 5.66667 1.50661 5.594 1.36401C5.53009 1.23856 5.4281 1.13658 5.30266 1.07266C5.16005 1 4.97337 1 4.6 1Z"
        stroke="#3B7358"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.9333 1H9.4C9.02663 1 8.83995 1 8.69734 1.07266C8.5719 1.13658 8.46991 1.23856 8.406 1.36401C8.33333 1.50661 8.33333 1.6933 8.33333 2.06667V4.6C8.33333 4.97337 8.33333 5.16005 8.406 5.30266C8.46991 5.4281 8.5719 5.53009 8.69734 5.594C8.83995 5.66667 9.02663 5.66667 9.4 5.66667H11.9333C12.3067 5.66667 12.4934 5.66667 12.636 5.594C12.7614 5.53009 12.8634 5.4281 12.9273 5.30266C13 5.16005 13 4.97337 13 4.6V2.06667C13 1.6933 13 1.50661 12.9273 1.36401C12.8634 1.23856 12.7614 1.13658 12.636 1.07266C12.4934 1 12.3067 1 11.9333 1Z"
        stroke="#3B7358"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.9333 8.33333H9.4C9.02663 8.33333 8.83995 8.33333 8.69734 8.406C8.5719 8.46991 8.46991 8.5719 8.406 8.69734C8.33333 8.83995 8.33333 9.02663 8.33333 9.4V11.9333C8.33333 12.3067 8.33333 12.4934 8.406 12.636C8.46991 12.7614 8.5719 12.8634 8.69734 12.9273C8.83995 13 9.02663 13 9.4 13H11.9333C12.3067 13 12.4934 13 12.636 12.9273C12.7614 12.8634 12.8634 12.7614 12.9273 12.636C13 12.4934 13 12.3067 13 11.9333V9.4C13 9.02663 13 8.83995 12.9273 8.69734C12.8634 8.5719 12.7614 8.46991 12.636 8.406C12.4934 8.33333 12.3067 8.33333 11.9333 8.33333Z"
        stroke="#3B7358"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.6 8.33333H2.06667C1.6933 8.33333 1.50661 8.33333 1.36401 8.406C1.23856 8.46991 1.13658 8.5719 1.07266 8.69734C1 8.83995 1 9.02663 1 9.4V11.9333C1 12.3067 1 12.4934 1.07266 12.636C1.13658 12.7614 1.23856 12.8634 1.36401 12.9273C1.50661 13 1.6933 13 2.06667 13H4.6C4.97337 13 5.16005 13 5.30266 12.9273C5.4281 12.8634 5.53009 12.7614 5.594 12.636C5.66667 12.4934 5.66667 12.3067 5.66667 11.9333V9.4C5.66667 9.02663 5.66667 8.83995 5.594 8.69734C5.53009 8.5719 5.4281 8.46991 5.30266 8.406C5.16005 8.33333 4.97337 8.33333 4.6 8.33333Z"
        stroke="#3B7358"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CategorySVGIcon;
