import styles from './ShopReview.module.scss';
import ShopProfileImage from '../../assets/images/shopProfileImage.png';
import StarRating from '../../ReusableCoponents/StarRating';

const ShopProfile = () => {
  return (
    <div className={styles.profileContainer}>
      <img src={ShopProfileImage} alt="" />
      <div className={styles.description}>
        <h6>ABC Barber Shop</h6>
        <div className={styles.rating}>
          <span>5.0</span>
          <StarRating />
        </div>
      </div>
    </div>
  );
};

export default ShopProfile;
