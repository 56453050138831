import { constRightSidebarToggle } from '../constants/Constants';

export const RightSideBarToggleHooks = (name, data) => {
  let tempConstRightSidebarToggle = structuredClone(constRightSidebarToggle);
  tempConstRightSidebarToggle[name] = !data[name];
  return tempConstRightSidebarToggle;
};

export const setTokenInLocalStorage = (name, value) => {
  try {
    localStorage.setItem(name, value);
  } catch (error) {
    console.error('Failed to set token in local storage : ', error);
  }
};

// Function to get token from local storage
export const getTokenFromLocalStorage = name => {
  return localStorage.getItem(name);
};

export const clearLocalStorage = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('role');
  localStorage.removeItem('authId');
};
