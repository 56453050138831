// src/RatingBarChart.js
/* eslint-disable import/namespace */
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
/* eslint-enable import/namespace */
import { Bar } from 'react-chartjs-2';

import starIcon from '../../assets/images/starImage.png'; // Ensure you have a star icon image in the src folder

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const data = {
  labels: ['5', '4', '3', '2', '1'],
  datasets: [
    {
      label: 'Ratings',
      data: [40, 20, 30, 25, 10],
      backgroundColor: 'rgba(254, 128, 75,1)',
      borderColor: 'rgba(255, 99, 71, 1)',
      borderWidth: 0
    }
  ]
};

const options = {
  indexAxis: 'y', // This makes the bar chart horizontal
  responsive: true,
  plugins: {
    legend: {
      display: false
    },
    title: {
      display: false,
      text: 'Horizontal Rating Bar Chart with Star Icons'
    }
  },
  scales: {
    x: {
      display: false,
      grid: {
        display: false,
        drawBorder: false
      }
    },
    y: {
      display: false,
      grid: {
        display: false,
        drawBorder: false
      }
    }
  },
  barThickness: '8',
  borderRadius: '10',
  barPercentage: '0.2',
  categoryPercentage: '0.8'
};

const customPlugin = {
  id: 'customLabel',
  afterDatasetsDraw(chart) {
    const { ctx } = chart;
    const yAxis = chart.scales.y;
    const xAxis = chart.scales.x;
    const { top, bottom } = yAxis;
    const barHeight = (bottom - top) / data.labels.length;

    data.labels.forEach((label, index) => {
      const y = yAxis.getPixelForTick(index) - barHeight / 2 + 5;
      const x = xAxis.left - 40; // Positioning the icon on the left of the bars
      const image = new Image();
      image.src = starIcon;

      image.onload = () => {
        ctx.drawImage(image, x, y, 20, 20); // Adjust size and position of the star icon
        // Draw label text
        ctx.fillStyle = '#666';
        ctx.font = 'bold 12px Arial';
        ctx.fillText(label, x + 25, y + 15);
      };
    });
  }
};

const RatingBarChart = () => {
  return <Bar data={data} options={options} plugins={[customPlugin]} />;
};

export default RatingBarChart;
