import { useState } from 'react';

import { getApprovedShopOwnerProfileByIdService } from '../../../apiServices/profile/ProfileServices';
import {
  CrossSvgIcon,
  EyesSvgIcon,
  LocationSvgIcon
} from '../../../assets/svgIcons/svgIcons';
import Modal from '../../../modals/Modal';
import ShopOwnerModal from '../../../modals/ShopOwnerModal/ShopOwnerModal';
import Loader from '../../../ReusableCoponents/Loader/Loader';

const ShopOwnerTableActions = ({ ownerId }) => {
  const [modal, setModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [ownerData, setOwnerData] = useState(null);

  const viewActionClickHandler = e => {
    setLoader(true);
    e.stopPropagation();

    setTimeout(() => {
      getApprovedShopOwnerProfileByIdService(ownerId).then(res => {
        // console.log("Owner Profile data is : ", res?.data)
        setOwnerData(res?.data);
        setLoader(false);
        setModal(true);
      });
    });
  };

  return (
    <div className="actions">
      <LocationSvgIcon />
      <div className="shop-actions" onClick={viewActionClickHandler}>
        {loader ? <Loader /> : <EyesSvgIcon />}
      </div>
      <CrossSvgIcon color="red" />
      {modal && (
        <Modal open={modal} closeModal={setModal}>
          <ShopOwnerModal ownerData={ownerData} setOwnerData={setOwnerData} />
        </Modal>
      )}
    </div>
  );
};

export default ShopOwnerTableActions;
