import { createSlice } from '@reduxjs/toolkit';
const authSlice = createSlice({
  name: 'auth',
  initialState: {
    // token: process.env.REACT_APP_TOKEN,
    token: '',
    role: '',
    authId: ''
  },

  reducers: {
    setTokenHandler(state, action) {
      state.token = action.payload;
    },
    setRoleHandler(state, action) {
      state.role = action.payload;
    },
    setAuthId(state, action) {
      state.authId = action.payload;
    },

    logoutHandler(state) {
      state.token = null;
      state.role = null;
      state.authId = null;
    }
  }
});

export const authActions = authSlice.actions;

export default authSlice;
