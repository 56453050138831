import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import styles from './Login.module.scss';
import { loginService } from '../apiServices/auth/AuthApiServices';
import loginImage from '../assets/images/loginImage.png';
import logoNew from '../assets/images/logoNew.png';
// import { status } from '../constants/AuthConstants';
import { authActions } from '../store/auth/AuthSlice';
import { setTokenInLocalStorage } from '../utils/customHooks';
import { checkLoginData } from '../utils/loginPageHooks';

// import { getProfileByIdService } from "../apiServices/profile/ProfileServices";
// import { profileActions } from "../store/profile/ProfileSlice";

function LoginPage() {
  const dispatch = useDispatch();

  const [loginData, setLoginData] = useState({
    email: '',
    mobileNumber: '',
    password: '',
    platform: 'ADMIN'
  });

  const loginHandler = () => {
    const tempLoginData = checkLoginData(loginData);

    if (tempLoginData) {
      loginService(tempLoginData).then(res => {
        if (res.status) {
          setTokenInLocalStorage('token', res.data.token);
          setTokenInLocalStorage('roles', res.data.roles);
          // setTokenInLocalStorage('authId', res?.data?.authData?._id);
          dispatch(authActions.setTokenHandler(res.data.token));
          dispatch(authActions.setRoleHandler(res.data.role));
          dispatch(authActions.setAuthId(res?.data?.authData?._id));
          // getProfile(res?.data?.authData?._id)
        }
      });
    }
  };

  const setFieldHandler = e => {
    let name = e.target.name;
    let value = e.target.value;

    setLoginData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  return (
    <div className={styles.outerContainer}>
      <div className={styles.innerContainer}>
        <div className={styles.leftContainer}>
          <img src={logoNew} alt="" />
          <div className={styles.headingContainer}>
            <span className={styles.heading}>Welcome Back!</span>
            <span className={styles.subHeading}>
              Please login to your account
            </span>
          </div>

          <div className={styles.inputContainer}>
            <input
              type="text"
              name="mobileNumber"
              value={loginData.mobileNumber}
              onChange={setFieldHandler}
              placeholder="get@ziontutorial.com"
            />
            <input
              name="password"
              value={loginData.password}
              onChange={setFieldHandler}
              type="text"
              placeholder="Password"
            />
            <Link to="/admin/forgot" className={styles.forgot}>
              Forgot Password
            </Link>
          </div>

          <Link onClick={loginHandler} className={styles.primaryBtn}>
            Log In
          </Link>
        </div>
        <div className={styles.rightContainer}>
          <img src={loginImage} alt="" />
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
