import styles from './ShopFilter.module.scss';
import { upArrowIcon } from '../assets';
import Button from '../ReusableCoponents/Button';
import InputCheckbox from '../ReusableCoponents/InputBox/InputCheckbox';
import PriceInputBox from '../ReusableCoponents/InputBox/PriceInputBox';
import PricePackageInputBox from '../ReusableCoponents/InputBox/PricePackageInputBox';
import SearchInputBox from '../ReusableCoponents/InputBox/SearchInputBox';

const ShopFilter = () => {
  return (
    <div className={styles.filterContainer}>
      <h4>Filter</h4>
      <div className={styles.filterOptionsContainer}>
        <div className={styles.locationContainer}>
          <h6 className={styles.subHeading}>Location</h6>
          <SearchInputBox />
        </div>

        <div className={styles.ratingContainer}>
          <div className={styles.ratingGroup}>
            <h6 className={styles.subHeading}>Rating</h6>
            <img src={upArrowIcon} alt="" />
          </div>

          <div className={styles.checkboxContainer}>
            <InputCheckbox option="All" />
            <InputCheckbox option="1 Star" />
            <InputCheckbox option="2 Star" />
            <InputCheckbox option="3 Star" />
            <InputCheckbox option="4 Star" />
            <InputCheckbox option="5 Star" />
          </div>
        </div>

        <div className={styles.priceRangeContainer}>
          <h6 className={styles.subHeading}>Price Range</h6>
          <PricePackageInputBox />
          <div className={styles.priceOptionContainer}>
            <PriceInputBox name="500" minMax="Min" />
            <PriceInputBox name="10000" minMax="Max" />
            <button className={styles.goButton}>Go</button>
          </div>
        </div>

        <div className={styles.container}>
          <h6 className={styles.subHeading}>Earning by Appointments</h6>
          <div className={styles.optionContainer}>
            <PriceInputBox name="500" minMax="Min" />
            <PriceInputBox name="10000" minMax="Max" />
            <button className={styles.goButton}>Go</button>
          </div>
        </div>

        <div className={styles.container}>
          <h6 className={styles.subHeading}>No. of Artists</h6>
          <div className={styles.optionContainer}>
            <PriceInputBox name="500" minMax="Min" />
            <PriceInputBox name="10000" minMax="Max" />
            <button className={styles.goButton}>Go</button>
          </div>
        </div>

        <div className={styles.serviceContainer}>
          <div className={styles.serviceGroup}>
            <h6 className={styles.subHeading}>Services</h6>
            <img src={upArrowIcon} alt="" />
          </div>

          <div className={styles.checkboxContainer}>
            <InputCheckbox option="All" />
            <InputCheckbox option="Unisex" />
            <InputCheckbox option="Male" />
            <InputCheckbox option="Female" />
          </div>
        </div>

        <div className={styles.buttonContainer}>
          <Button title="Apply" color="primary" />
          <Button title="Cancel" color="secondary" />
        </div>
      </div>
    </div>
  );
};

export default ShopFilter;
