const PincodeSVGIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.05 10.9L0.5 13.5V3.1L5.05 0.5M5.05 10.9L9.6 13.5M5.05 10.9V0.5M9.6 13.5L13.5 10.9V0.5L9.6 3.1M9.6 13.5V3.1M9.6 3.1L5.05 0.5"
        stroke="#3B7358"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PincodeSVGIcon;
