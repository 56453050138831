export const shopList = [
  {
    id: '1',
    owner: 'Ram Kumar',
    branches: '2',
    email: 'abc@gmail.com',
    phone: '9876543210',
    address: 'Vaishali Nagar, Jaipur',
    serviceType: 'Male'
  },
  {
    id: '2',
    owner: 'Ram Kumar',
    branches: '2',
    email: 'abc@gmail.com',
    phone: '9876543210',
    address: 'Vaishali Nagar, Jaipur',
    serviceType: 'Female'
  },
  {
    id: '3',
    owner: 'Ram Kumar',
    branches: '2',
    email: 'abc@gmail.com',
    phone: '9876543210',
    address: 'Vaishali Nagar, Jaipur',
    serviceType: 'Male'
  },
  {
    id: '4',
    owner: 'Ram Kumar',
    branches: '2',
    email: 'abc@gmail.com',
    phone: '9876543210',
    address: 'Vaishali Nagar, Jaipur',
    serviceType: 'Female'
  },
  {
    id: '5',
    owner: 'Ram Kumar',
    branches: '2',
    email: 'abc@gmail.com',
    phone: '9876543210',
    address: 'Vaishali Nagar, Jaipur',
    serviceType: 'Male'
  },
  {
    id: '6',
    owner: 'Ram Kumar',
    branches: '2',
    email: 'abc@gmail.com',
    phone: '9876543210',
    address: 'Vaishali Nagar, Jaipur',
    serviceType: 'Female'
  },
  {
    id: '7',
    owner: 'Ram Kumar',
    branches: '2',
    email: 'abc@gmail.com',
    phone: '9876543210',
    address: 'Vaishali Nagar, Jaipur',
    serviceType: 'Male'
  },
  {
    id: '8',
    owner: 'Ram Kumar',
    branches: '2',
    email: 'abc@gmail.com',
    phone: '9876543210',
    address: 'Vaishali Nagar, Jaipur',
    serviceType: 'Female'
  },
  {
    id: '9',
    owner: 'Ram Kumar',
    branches: '2',
    email: 'abc@gmail.com',
    phone: '9876543210',
    address: 'Vaishali Nagar, Jaipur',
    serviceType: 'Unisex'
  }
];

export const shopOwnerColumnName = [
  {
    id: 1,
    name: 'Sr.No.'
  },
  {
    id: 2,
    name: 'Owner Name'
  },
  {
    id: 3,
    name: 'Email Address'
  },
  {
    id: 4,
    name: 'Address'
  },
  {
    id: 5,
    name: 'Active Account'
  },
  {
    id: 6,
    name: 'Actions'
  }
];
