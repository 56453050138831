import { createSlice } from '@reduxjs/toolkit';
const shopSlice = createSlice({
  name: 'shop',
  initialState: {
    approvedOwnersProfile: []
  },
  reducers: {
    setApprovedOwnersProfile(state, action) {
      state.approvedOwnersProfile = action.payload;
    },
    setOwnerProfileInList(state, action) {
      const tempApprovedOwnersProfile = state.approvedOwnersProfile.map(
        profile => {
          if (profile._id === action.payload._id) {
            return action.payload;
          } else {
            return profile;
          }
        }
      );
      state.approvedOwnersProfile = tempApprovedOwnersProfile;
    }
  }
});

export const shopActions = shopSlice.actions;

export default shopSlice;
