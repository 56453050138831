import ArtistsContainer from './ArtistsContainer';
import BarChartContainer from './BarChartContainer';
import CustomerList from './CustomerList';
import OwnerProfile from './OwnerProfile';
import PieChartContainer from './PieChartContainer';
import styles from './ShopDashboardPage.module.scss';
import ShopRating from './ShopRating';
import AdminHeader from '../../layout/adminheader/AdminHeader';

const ShopDashboard = () => {
  return (
    <div className="middleData">
      <AdminHeader title="Shop Owner" />
      <div className={styles.outerContainer}>
        <div className={styles.profileDetails}>
          <OwnerProfile />
          <ShopRating />
        </div>
        <div className={styles.chartContainer}>
          <BarChartContainer />
          <PieChartContainer />
        </div>
        <div className={styles.tableContainer}>
          <ArtistsContainer />
          <CustomerList />
        </div>
      </div>
    </div>
  );
};

export default ShopDashboard;
