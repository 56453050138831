import styles from './ShopDashboardPage.module.scss';
import PieChart from '../../ReusableCoponents/PieChart/PieChart';

const PieChartContainer = () => {
  return (
    <div className={styles.pieChartContainer}>
      <div className={styles.pieChartHeader}>
        <span>Total Appointments</span>
        <select name="days" id="days">
          <option value="Last 7 days">Last 7 days</option>
          <option value="Last 5 days">Last 5 days</option>
          <option value="Last 3 days">Last 3 days</option>
          <option value="Last 2 days">Last 2 days</option>
        </select>
      </div>
      <div className={styles.pieChart}>
        <PieChart />
      </div>
    </div>
  );
};

export default PieChartContainer;
