import styles from './ShopDashboardPage.module.scss';
import { customerImage, premiumIcon } from '../../assets';

const CustomerList = () => {
  return (
    <div className={styles.customerContainer}>
      <div className={styles.heading}>
        <h3>Customers</h3>
        <button>View all</button>
      </div>

      <div className={styles.customerList}>
        <div className={styles.customer}>
          <div>
            <img src={customerImage} alt="" />
            <span>Vikas Sharma</span>
          </div>
          <h6>Regular</h6>
        </div>
        <div className={styles.customer}>
          <div>
            <img src={customerImage} alt="" />
            <span>Omesh Kumar</span>
          </div>
          <img src={premiumIcon} alt="" />
        </div>
        <div className={styles.customer}>
          <div>
            <img src={customerImage} alt="" />
            <span>Vikas Sharma</span>
          </div>
          <h6>Regular</h6>
        </div>
        <div className={styles.customer}>
          <div>
            <img src={customerImage} alt="" />
            <span>Omesh Kumar</span>
          </div>
          <img src={premiumIcon} alt="" />
        </div>
        <div className={styles.customer}>
          <div>
            <img src={customerImage} alt="" />
            <span>Vikas Sharma</span>
          </div>
          <h6>Regular</h6>
        </div>
      </div>
    </div>
  );
};

export default CustomerList;
