import styles from './shopServices.module.scss';
import descriptionIcon from '../../assets/icons/descriptionIcon.png';
import priceIcon from '../../assets/icons/priceIcon.png';
import statusIcon from '../../assets/icons/statusIcon.png';
import packagei from '../../assets/images/package.png';

const PackageCard = () => {
  return (
    <div className={styles.cardContainer}>
      <img src={packagei} alt="" />
      <span>Package One</span>
      <div className={styles.packageDetails}>
        <div className={styles.box}>
          <img src={priceIcon} alt="" />
          <div className={styles.text}>
            <span className={styles.textHeading}>Price</span>
            <span className={styles.textContent}>₹400</span>
          </div>
        </div>
        <div className={styles.box}>
          <img src={statusIcon} alt="" />
          <div className={styles.text}>
            <span className={styles.textHeading}>Status</span>
            <span className={styles.textContent}>Active</span>
          </div>
        </div>
        <div className={styles.box}>
          <img src={descriptionIcon} alt="" />
          <div className={styles.text}>
            <span className={styles.textHeading}>Description</span>
            <span className={styles.textContent}>fvgfn fgmbkjmk </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PackageCard;
