import {
  appointmentList,
  appointmentListColumnName
} from '../../constants/ShopAppointments';
import AdminHeader from '../../layout/adminheader/AdminHeader';
import PageHeader from '../../layout/pageHeader/PageHeader';
import AppointmentsTable from '../../tables/AppointmentsTable';

const ShopAppointments = () => {
  return (
    <div className="middleData">
      <AdminHeader title="ABC Barber Shop" />
      <PageHeader heading="Appointments" />
      <AppointmentsTable
        appointmentList={appointmentList}
        appointmentListColumnName={appointmentListColumnName}
      />
    </div>
  );
};

export default ShopAppointments;
