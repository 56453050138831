import instance from '../axiosInstance';

export const getProfileByIdService = async id => {
  try {
    const response = await instance.get(`/admin/profile/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching customers:', error);
    return error;
  }
};

export const editProfileByIdService = async (id, data) => {
  try {
    const response = await instance.put(`/admin/profile/${id}`, data);
    return response.data;
  } catch (error) {
    console.error('Error editing profile: ', error);
    return error;
  }
};

export const getApprovedShopOwnerProfileByIdService = async ownerId => {
  try {
    const response = await instance.get(`/admin/ownerprofile/${ownerId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching owner with the ownerId : ', error);
    return error;
  }
};

export const updateApprovedShopOwnerProfileByIdService = async (
  ownerId,
  data
) => {
  try {
    console.log('data', data);
    const response = await instance.patch(
      `/admin/ownerprofile/${ownerId}`,
      data
    );
    return response.data;
  } catch (error) {
    console.error(`Error updating details of owner : `, error);
    return error;
  }
};
