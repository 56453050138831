import './ShopProfile.scss';
import { Link } from 'react-router-dom';

import { ProfileImg } from '../../assets';
import AdminHeader from '../../layout/adminheader/AdminHeader';

function ShopProfile() {
  return (
    <div className="middleData">
      <AdminHeader title="ABC Barber Shop" />
      <div className="shopProfile">
        <div className="profileBoxBg"></div>
        <div className="profileboxsContainer">
          <div className="ShopProfileBox">
            <img src={ProfileImg} alt="error" />
            <div className="shopName">
              <h5>ABC Barber Shop</h5>
              <p>
                <span>
                  <i className="bi bi-geo-alt"></i>
                </span>
                <span>Jaipur Rajasthan</span>
              </p>
            </div>
            <ul className="shopFeatureList">
              <li className="shopFeature">
                <p>Branches</p>
                <h6>25</h6>
              </li>
              <li className="shopFeature">
                <p>Artists</p>
                <h6>643</h6>
              </li>
              <li className="shopFeature">
                <p>Customers</p>
                <h6>76</h6>
              </li>
            </ul>
            <div className="shopBntBox">
              <Link to="/admin/shop/editProfile">
                <button className="adminMainBtn">
                  <span>
                    <i className="bi bi-pencil-square"></i>
                  </span>
                  <span>Edit</span>
                </button>
              </Link>
              <button className="adminSecondryBtn">
                <span>
                  <i className="bi bi-geo-alt"></i>
                </span>
                <span>Location</span>
              </button>
            </div>
            <div className="shopProfileFooter">
              <button>Disable this account</button>
            </div>
          </div>
          <div className="ShopDetailBox">
            <h5> Shop Detail </h5>

            <ul className="shopDetailList">
              <li className="shopDetailListItem">
                <span className="shopDetailIcon">
                  <i className="bi bi-person"></i>
                </span>
                <span className="shopDetailText">
                  <p>Owner name</p>
                  <h6>Ram Kumar</h6>
                </span>
              </li>
              <li className="shopDetailListItem">
                <span className="shopDetailIcon">
                  <i className="bi bi-envelope"></i>
                </span>
                <span className="shopDetailText">
                  <p>Email</p>
                  <h6>abc@gmail.com</h6>
                </span>
              </li>
              <li className="shopDetailListItem">
                <span className="shopDetailIcon">
                  <i className="bi bi-telephone"></i>
                </span>
                <span className="shopDetailText">
                  <p>Phone number</p>
                  <h6>+91 9876543210</h6>
                </span>
              </li>
              <li className="shopDetailListItem">
                <span className="shopDetailIcon">
                  <i className="bi bi-grid"></i>
                </span>
                <span className="shopDetailText">
                  <p>Category</p>
                  <h6>Male</h6>
                </span>
              </li>
              <li className="shopDetailListItem">
                <span className="shopDetailIcon">
                  <i className="bi bi-map"></i>
                </span>
                <span className="shopDetailText">
                  <p>Pincode</p>
                  <h6>302012</h6>
                </span>
              </li>
              <li className="shopDetailListItem">
                <span className="shopDetailIcon">
                  <i className="bi bi-geo-alt"></i>
                </span>
                <span className="shopDetailText">
                  <p>Address</p>
                  <h6>11-a Jaipur Rajasthan</h6>
                </span>
              </li>
              <li className="shopDetailListItem">
                <span className="shopDetailIcon">
                  <i className="bi bi-person"></i>
                </span>
                <span className="shopDetailText">
                  <p>Owner name</p>
                  <h6>Ram Kumar</h6>
                </span>
              </li>
              <li className="shopDetailListItem">
                <span className="shopDetailIcon">
                  <i className="bi bi-envelope"></i>
                </span>
                <span className="shopDetailText">
                  <p>Email</p>
                  <h6>abc@gmail.com</h6>
                </span>
              </li>
              <li className="shopDetailListItem">
                <span className="shopDetailIcon">
                  <i className="bi bi-telephone"></i>
                </span>
                <span className="shopDetailText">
                  <p>Phone number</p>
                  <h6>+91 9876543210</h6>
                </span>
              </li>
              <li className="shopDetailListItem">
                <span className="shopDetailIcon">
                  <i className="bi bi-grid"></i>
                </span>
                <span className="shopDetailText">
                  <p>Category</p>
                  <h6>Male</h6>
                </span>
              </li>
              <li className="shopDetailListItem">
                <span className="shopDetailIcon">
                  <i className="bi bi-map"></i>
                </span>
                <span className="shopDetailText">
                  <p>Pincode</p>
                  <h6>302012</h6>
                </span>
              </li>
              <li className="shopDetailListItem">
                <span className="shopDetailIcon">
                  <i className="bi bi-geo-alt"></i>
                </span>
                <span className="shopDetailText">
                  <p>Address</p>
                  <h6>11-a Jaipur Rajasthan</h6>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShopProfile;
