export const customerList = [
  {
    id: '1',
    name: 'Ram Kumar',
    address: 'Vaishali Nagar, Jaipur',
    email: 'abc@gmail.com',
    phone: '9876543210',
    appointments: 'No Appointments',
    rating: 3
  },
  {
    id: '2',
    name: 'Ram Kumar',
    address: 'Vaishali Nagar, Jaipur',
    email: 'abc@gmail.com',
    phone: '9876543210',
    appointments: 'Two Appointments',
    rating: 2
  },
  {
    id: '3',
    name: 'Ram Kumar',
    address: 'Vaishali Nagar, Jaipur',
    email: 'abc@gmail.com',
    phone: '9876543210',
    appointments: 'No Appointments',
    rating: 2.5
  },
  {
    id: '4',
    name: 'Ram Kumar',
    address: 'Vaishali Nagar, Jaipur',
    email: 'abc@gmail.com',
    phone: '9876543210',
    appointments: 'Two Appointments',
    rating: 3
  },
  {
    id: '5',
    name: 'Ram Kumar',
    address: 'Vaishali Nagar, Jaipur',
    email: 'abc@gmail.com',
    phone: '9876543210',
    appointments: 'Two Appointments',
    rating: 3.5
  },
  {
    id: '6',
    name: 'Ram Kumar',
    address: 'Vaishali Nagar, Jaipur',
    email: 'abc@gmail.com',
    phone: '9876543210',
    appointments: 'Two Appointments',
    rating: 4
  },
  {
    id: '7',
    name: 'Ram Kumar',
    address: 'Vaishali Nagar, Jaipur',
    email: 'abc@gmail.com',
    phone: '9876543210',
    appointments: 'No Appointments',
    rating: 4.5
  },
  {
    id: '8',
    name: 'Ram Kumar',
    address: 'Vaishali Nagar, Jaipur',
    email: 'abc@gmail.com',
    phone: '9876543210',
    appointments: 'No Appointments',
    rating: 5
  }
];

export const customerColumnName = [
  { id: 1, name: 'Sr No.' },
  { id: 2, name: 'Name' },
  { id: 3, name: 'Address' },
  { id: 4, name: 'Email Address' },
  { id: 5, name: 'Phone Number' },
  { id: 6, name: 'Active Appointments' },
  { id: 7, name: 'Overall Rating' }
];
