import './ShopProfile.scss';
import AdminHeader from '../../layout/adminheader/AdminHeader';

const EditProfile = () => {
  return (
    <div className="middleData">
      <AdminHeader title="ABC Barber Shop" />
      <div className="formContainer">
        <h6 className="formHeading">Edit Shop Detail</h6>
        <div className="form">
          <p>Owner name</p>
          <input type="text" />

          <div className="secondRow">
            <div className="Email">
              <p>Email</p>
              <input type="text" />
            </div>
            <div className="PhoneNumber">
              <p>Phone number</p>
              <input type="text" />
            </div>
          </div>

          <div className="thirdRow">
            <div className="Pincode">
              <p>Pincode</p>
              <input type="text" />
            </div>
            <div className="Address">
              <p>Address</p>
              <input type="text" />
            </div>
          </div>
          <div className="fourthRow">
            <p>Category</p>
            <div className="options">
              <div className="Male">
                <input type="radio" name="category" />
                <span>Male</span>
              </div>
              <div className="Female">
                <input type="radio" name="category" />
                <span>Female</span>
              </div>
              <div className="Both">
                <input type="radio" name="category" />
                <span>Both</span>
              </div>
            </div>
          </div>
          <div className="buttons">
            <button className="primaryButton">Save</button>
            <button className="secondaryButton">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProfile;
