import styles from '../ReusableStyles.module.scss';

const InputCheckbox = ({ option }) => {
  return (
    <div className={styles.ratingBox}>
      <span>{option}</span>
      <input type="checkbox" name="" id="" />
    </div>
  );
};

export default InputCheckbox;
