import { useState } from 'react';

import styles from './ShopModal.module.scss';
import { updateShopDataByShopId } from '../../apiServices/Shop/ShopApiServices';
import { dashboardPhoto1 } from '../../assets';
import CallSVGIcon from '../../assets/svgIcons/CallSVGIcon/CallSVGIcon';
import CategorySVGIcon from '../../assets/svgIcons/CategorySVGIcon/CategorySVGIcon';
import EmailSVGIcon from '../../assets/svgIcons/EmailSVGIcon/EmailSVGIcon';
import LocationSVGIcon from '../../assets/svgIcons/LocationSVGIcon/LocationSVGIcon';
import PincodeSVGIcon from '../../assets/svgIcons/PincodeSVGIcon/PincodeSVGIcon';
import { status } from '../../constants/AuthConstants';

const ShopModal = ({ shopData }) => {
  const [updatedData, setUpdatedData] = useState(shopData);

  const approveClickHandler = async () => {
    setUpdatedData(prevData => {
      const newUpdatedData = {
        ...prevData,
        activeProfile: !prevData.activeProfile
      };

      updateShopDataByShopId(shopData?._id, updatedData).then(response => {
        if (response.status === status.success) {
          console.log(
            `activeProfile changed to ${newUpdatedData.activeProfile}`
          );
        }
      });

      return updatedData;
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <span>{shopData?.shopName}</span>
        <LocationSVGIcon />
      </div>

      <div className={styles.subSection}>
        <span>Shop Details</span>
        <div className={styles.details}>
          <div className={styles.leftSection}>
            <div className={styles.cards}>
              <div className={styles.logo}>
                <CallSVGIcon />
                <h6>Phone Number</h6>
              </div>
              <h5>{shopData?.authId?.mobileNumber}</h5>
            </div>

            <div className={styles.cards}>
              <div className={styles.logo}>
                <PincodeSVGIcon />
                <h6>Pincode</h6>
              </div>
              <h5>{shopData?.address?.pincode}</h5>
            </div>

            <div className={styles.cards}>
              <div className={styles.logo}>
                <CategorySVGIcon />
                <h6>Category</h6>
              </div>
              <h5>Male</h5>
            </div>
          </div>

          <div className={styles.rightSection}>
            <div className={styles.cards}>
              <div className={styles.logo}>
                <EmailSVGIcon />
                <h6>Email</h6>
              </div>
              <h5>{shopData?.authId?.email}</h5>
            </div>

            <div className={styles.cards}>
              <div className={styles.logo}>
                <PincodeSVGIcon />
                <h6>Aadhar Card Number</h6>
              </div>
              <h5>465645380974</h5>
            </div>

            <div className={styles.cards}>
              <div className={styles.logo}>
                <LocationSVGIcon />
                <h6>Address</h6>
              </div>
              <h5>{`${shopData?.address?.street} ${shopData?.address?.city} ${shopData?.address?.state} ${shopData?.address?.country}`}</h5>
            </div>
          </div>
        </div>
      </div>

      {/* <div className={styles.subSection}>
                <span>Aadhar Card Photos</span>
                <div className={styles.aadharSection}>
                    <div><img src="" alt="" /></div>
                    <div><img src="" alt="" /></div>
                </div>
            </div> */}

      <div className={styles.subSection}>
        <span>Photos</span>
        <div className={styles.photoSection}>
          <div>
            <img src={dashboardPhoto1 || shopData?.shopFrontPhoto} alt="" />
          </div>
          <div>
            <img src={dashboardPhoto1 || shopData?.shopInnerPhoto} alt="" />
          </div>
          <div>
            <img src={dashboardPhoto1 || shopData?.shopPhoto} alt="" />
          </div>
        </div>
      </div>

      <div className={styles.buttons}>
        <button
          className={styles.approve}
          onClick={approveClickHandler}
          disabled={shopData?.activeProfile}>
          Approve
        </button>
        <button className={styles.cancel}>Cancel</button>
      </div>
    </div>
  );
};

export default ShopModal;
