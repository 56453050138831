/* eslint-disable import/namespace */
import { Chart as ChartJS, ArcElement, Title, Tooltip, Legend } from 'chart.js';
/* eslint-enable import/namespace */
import { Pie } from 'react-chartjs-2';

import { data, options } from '../../constants/PieChartConstants';

ChartJS.register(ArcElement, Title, Tooltip, Legend);

const PieChart = () => {
  return <Pie data={data} options={options} />;
};

export default PieChart;
