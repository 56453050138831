import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import {
  branchesIcon,
  reviewListIcon,
  servicesIcon,
  shopAppointmentsIcon,
  customerIcon,
  shopDashboardIcon,
  packagesIcon
} from '../../assets';

const SubMenu = () => {
  const handleSetActiveLink = link => {
    setActiveLink(link);
  };

  const path = useLocation();

  const [activeLink, setActiveLink] = useState(path.pathname);

  return (
    <div className="sidebar">
      <span className="sidebarHeading">ABC Barber Shop</span>
      <ul className="sidebarList">
        <li
          className={`sidebarListItem ${
            activeLink === '/admin/shops/shopDashboard' ? 'active' : ''
          }`}>
          <Link
            to="/admin/shops/shopDashboard"
            onClick={() => handleSetActiveLink('/admin/shops/shopDashboard')}>
            <span>
              <img src={shopDashboardIcon} alt="" />
            </span>
            <span>Shop Dashboard</span>
          </Link>
        </li>

        <li
          className={`sidebarListItem ${
            activeLink === '/admin/shops/shopBranches' ? 'active' : ''
          }`}>
          <Link
            to="/admin/shops/shopBranches"
            onClick={() => handleSetActiveLink('/admin/shops/shopBranches')}>
            <span>
              <img src={branchesIcon} alt="" />
            </span>
            <span>Branches</span>
          </Link>
        </li>

        <li
          className={`sidebarListItem ${
            activeLink === '/admin/shops/shopAppointments' ? 'active' : ''
          }`}>
          <Link
            to="/admin/shops/shopAppointments"
            onClick={() =>
              handleSetActiveLink('/admin/shops/shopAppointments')
            }>
            <span>
              <img src={shopAppointmentsIcon} alt="" />
            </span>
            <span>Shop Appointments</span>
          </Link>
        </li>

        <li
          className={`sidebarListItem ${
            activeLink === '/admin/shops/shopServices' ? 'active' : ''
          }`}>
          <Link
            to="/admin/shops/shopServices"
            onClick={() => handleSetActiveLink('/admin/shops/shopServices')}>
            <span>
              <img src={servicesIcon} alt="" />
            </span>
            <span>Services & Packages</span>
          </Link>
        </li>

        <li
          className={`sidebarListItem ${
            activeLink === '/admin/shops/shopArtists' ? 'active' : ''
          }`}>
          <Link
            to="/admin/shops/shopArtists"
            onClick={() => handleSetActiveLink('/admin/shops/shopArtists')}>
            <span>
              <img src={packagesIcon} alt="" />
            </span>
            <span>Shop Artists</span>
          </Link>
        </li>

        <li
          className={`sidebarListItem ${
            activeLink === '/admin/shops/shopCustomers' ? 'active' : ''
          }`}>
          <Link
            to="/admin/shops/shopCustomers"
            onClick={() => handleSetActiveLink('/admin/shops/shopCustomers')}>
            <span>
              <img src={customerIcon} alt="" />
            </span>
            <span>Shop Customers</span>
          </Link>
        </li>

        <li
          className={`sidebarListItem ${
            activeLink === '/admin/shops/shopReviews' ? 'active' : ''
          }`}>
          <Link
            to="/admin/shops/shopReviews"
            onClick={() => handleSetActiveLink('/admin/shops/shopReviews')}>
            <span>
              <img src={reviewListIcon} alt="" />
            </span>
            <span>Review List</span>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default SubMenu;
