import { useState } from 'react';
import './AdminHeader.scss';
import { useDispatch } from 'react-redux';
// import { RightSideBarToggleHooks } from '../../utils/customHooks';
import { Navigate } from 'react-router-dom';

import SearchBox from './Helpers';
import { bellIcon, searchIcon, headerProfileImage } from '../../assets';
import { authActions } from '../../store/auth/AuthSlice';
import { uiActions } from '../../store/ui/UiSlice';
import { clearLocalStorage } from '../../utils/customHooks';

function AdminHeader({ title }) {
  const dispatch = useDispatch();

  const notificationHandler = () => {
    dispatch(uiActions.setRightSidebarHandler('/admin/dashboard'));
  };

  const setRightSideBarValue = () => {
    dispatch(uiActions.setRightSidebarHandler('profile'));
  };

  const [searchBox, setSearchBox] = useState(false);

  const searchBoxHandler = () => {
    setSearchBox(true);
  };

  const handleLogOutClickHandler = () => {
    clearLocalStorage();
    dispatch(authActions.logoutHandler());
    return <Navigate to="/admin/login/" replace />;
  };

  return (
    <div className="header">
      <div className="pageHeading">
        <span>{title}</span>
      </div>

      <div className="headerActions">
        <button className="adminBtn">Create Admin</button>
        <button className="adminBtn" onClick={handleLogOutClickHandler}>
          Log out
        </button>

        <button
          className={searchBox ? 'boxIcon' : 'icon'}
          onClick={searchBoxHandler}>
          {searchBox ? <SearchBox /> : <img src={searchIcon} alt="" />}
        </button>

        <button className="profile" onClick={setRightSideBarValue}>
          <img src={headerProfileImage} alt="" />
        </button>

        <button className="icon" onClick={notificationHandler}>
          <img src={bellIcon} alt="" />
        </button>
      </div>
    </div>
  );
}

export default AdminHeader;
