export const services = [
  'Hair Cut',
  'Hair Spa',
  'Hair Styling',
  'Massage',
  'Shaving',
  'Makeup',
  'Facial',
  'Skin Treatment',
  'Hair Treatment'
];
