import { useEffect, useRef, useState } from 'react';
// import { branchProfileImage } from '../../assets'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import styles from './ShopOwnerModal.module.scss';
import { updateApprovedShopOwnerProfileByIdService } from '../../apiServices/profile/ProfileServices';
import { getOwnerProfileId } from '../../apiServices/Shop/ShopApiServices';
import CallSVGIcon from '../../assets/svgIcons/CallSVGIcon/CallSVGIcon';
import CategorySVGIcon from '../../assets/svgIcons/CategorySVGIcon/CategorySVGIcon';
import EmailSVGIcon from '../../assets/svgIcons/EmailSVGIcon/EmailSVGIcon';
import LocationSVGIcon from '../../assets/svgIcons/LocationSVGIcon/LocationSVGIcon';
import PincodeSVGIcon from '../../assets/svgIcons/PincodeSVGIcon/PincodeSVGIcon';
import { Camera } from '../../assets/svgIcons/svgIcons';
import { status } from '../../constants/AuthConstants';
import Loader from '../../ReusableCoponents/Loader/Loader';
import { shopActions } from '../../store/shop/ShopSlice';

const ShopOwnerModal = ({ ownerData, setOwnerData }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);

  const [isEditable, setIsEditable] = useState(false);
  const [editedData, setEditedData] = useState({});
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(
    ownerData?.profilePhoto?.url
  );
  const [loader, setLoader] = useState(false);

  const viewDashboardButtonHandler = () => {
    navigate(`/admin/shops/shopDashboard/${ownerData?._id}`);
  };

  const clickHandler = e => {
    e.stopPropagation();
  };

  const editProfileClickHandler = () => {
    setIsEditable(true);

    setEditedData(ownerData);
  };

  const handleChange = event => {
    const { name, value } = event.target;
    setEditedData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleAddressChange = event => {
    console.log('event', event.target);
    const { name, value } = event.target;
    setEditedData(prevData => ({
      ...prevData,
      address: {
        ...prevData.address,
        [name]: value
      }
    }));
  };

  const handleFileChange = event => {
    console.log('event.target.files', event.target.files);
    const file = event.target.files[0];
    setProfilePhoto(file);
    // setPreviewPhoto(file?.name)
    // setImagePreviewUrl(file)
    // console.log("previewPhoto", previewPhoto)

    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreviewUrl(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  const saveProfileClickHandler = async () => {
    setLoader(true);
    const formData = new FormData();
    if (isEditable) {
      formData.append('ownerName', editedData.ownerName);
      // formData.append('email', editedData.authId.email)
      // formData.append('mobileNumber', editedData.authId.mobileNumber)
      formData.append(
        'address',
        JSON.stringify({
          street: editedData.address.street,
          city: editedData.address.city,
          state: editedData.address.state,
          country: editedData.address.country,
          pincode: editedData.address.pincode
        })
      );

      // formData.append('pincode', editedData.address.pincode)
      // formData.append('street', editedData.address.street)
      // formData.append('city', editedData.address.city)
      // formData.append('state', editedData.address.state)
      // formData.append('country', editedData.address.country)
    }

    if (profilePhoto) {
      // console.log("profilePhoto", profilePhoto.name)
      formData.append('profilePhoto', profilePhoto);
    }

    try {
      const response = await updateApprovedShopOwnerProfileByIdService(
        ownerData?._id,
        formData
      );

      if (response.status === status.success) {
        setLoader(false);
        setIsEditable(false);
        const res = await getOwnerProfileId(ownerData._id);
        if (res?.status === status.success) {
          setOwnerData(res?.data);
          dispatch(shopActions.setOwnerProfileInList(res?.data));
        }
        // setOwnerData(editedData);
      }
    } catch (error) {
      console.error('Form submission error:', error);
    }
  };

  const cancelClickHandler = () => {
    setEditedData(ownerData);
    setImagePreviewUrl(null);
    setIsEditable(false);
  };

  useEffect(() => {
    if (ownerData) {
      setEditedData(ownerData);
    }
  }, [ownerData]);

  return (
    <div className={styles.outerCardContainer} onClick={e => clickHandler(e)}>
      <div className={styles.cardContainer}>
        <div className={styles.leftSide}>
          <div className={styles.profile}>
            <div className={styles.profileImageContainer}>
              <img
                src={imagePreviewUrl || ownerData?.profilePhoto?.url}
                alt=""
              />

              {isEditable && (
                <div className={styles.changePhoto} onClick={triggerFileInput}>
                  <Camera />
                </div>
              )}

              <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileChange}
              />
            </div>

            <div className={styles.details}>
              {isEditable ? (
                <input
                  type="text"
                  value={editedData?.ownerName}
                  onChange={handleChange}
                  name="ownerName"
                />
              ) : (
                <h2>{editedData?.ownerName}</h2>
              )}
              <div className={styles.location}>
                <LocationSVGIcon />
                <h6
                  className={
                    styles.editableLocation
                  }>{`${editedData?.address?.city || ''} ${editedData?.address?.state || ''}`}</h6>
              </div>
            </div>
          </div>
          <div className={styles.lowerBox}>
            <div className={styles.child}>
              <span>Artists</span>
              <h6>643</h6>
            </div>
            <div className={styles.child}>
              <span>Customers</span>
              <h6>76</h6>
            </div>
          </div>
        </div>

        <div className={styles.line}></div>

        {/*-------------------- card right section starts------------------ */}
        <div className={styles.rightSide}>
          <h2>Branch Detail</h2>

          <div className={styles.detailsBox}>
            <div className={styles.logo}>
              <EmailSVGIcon />
              <span>Email</span>
            </div>
            <h6 className={styles.rightDescription}>
              {editedData?.authId?.email}
            </h6>
          </div>

          <div className={styles.detailsBox}>
            <div className={styles.logo}>
              <CallSVGIcon />
              <span>Phone number</span>
            </div>
            <h6 className={styles.rightDescription}>
              {editedData?.authId?.mobileNumber || 9876543210}
            </h6>
          </div>

          <div className={styles.detailsBox}>
            <div className={styles.logo}>
              <CategorySVGIcon />
              <span>Category</span>
            </div>
            <h6 className={styles.rightDescription}>Male</h6>
          </div>

          <div className={styles.detailsBox}>
            <div className={styles.logo}>
              <PincodeSVGIcon />
              <span>Pincode</span>
            </div>

            <h6 className={styles.rightDescription}>
              {isEditable
                ? ownerData?.address?.pincode
                : editedData?.address?.pincode}
            </h6>
          </div>

          <div className={styles.detailsBox}>
            <div className={styles.logo}>
              <LocationSVGIcon />
              <span>Address</span>
            </div>
            {isEditable ? (
              <div className={styles.address}>
                <input
                  type="text"
                  placeholder="street"
                  value={editedData?.address?.street || ''}
                  onChange={handleAddressChange}
                  name="street"
                />

                <input
                  type="text"
                  placeholder="city"
                  value={editedData?.address?.city || ''}
                  onChange={handleAddressChange}
                  name="city"
                />

                <input
                  type="text"
                  placeholder="state"
                  value={editedData?.address?.state || ''}
                  onChange={handleAddressChange}
                  name="state"
                />

                <input
                  type="text"
                  placeholder="country"
                  value={editedData?.address?.country || ''}
                  onChange={handleAddressChange}
                  name="country"
                />

                <input
                  type="text"
                  placeholder="pincode"
                  value={editedData?.address?.pincode || ''}
                  onChange={handleAddressChange}
                  name="pincode"
                />
              </div>
            ) : (
              <h6
                className={
                  styles.rightDescription
                }>{`${editedData?.address?.street || ''} ${editedData?.address?.city || ''} ${editedData?.address?.state || ''} ${editedData?.address?.country || ''}`}</h6>
            )}
          </div>
        </div>
      </div>
      <div className={styles.buttonBox}>
        {isEditable && (
          <button className={styles.cancel} onClick={cancelClickHandler}>
            Cancel
          </button>
        )}
        {isEditable ? (
          loader ? (
            <Loader />
          ) : (
            <button
              className={styles.location}
              onClick={saveProfileClickHandler}>
              Save Profile
            </button>
          )
        ) : (
          <button className={styles.location} onClick={editProfileClickHandler}>
            Edit Profile
          </button>
        )}
        <button
          className={styles.dashboard}
          onClick={viewDashboardButtonHandler}>
          View Dashboard
        </button>
      </div>
    </div>
  );
};

export default ShopOwnerModal;
