import './Artist.scss';
import {
  artistsColumnName,
  artistsList
} from '../../../constants/ArtistsConstants';
import AdminHeader from '../../../layout/adminheader/AdminHeader';
import ArtistsTable from '../../../tables/ArtistsTable';

function Artist() {
  return (
    <div className="middleData">
      <AdminHeader title="Artists" />
      <ArtistsTable
        artistsColumnName={artistsColumnName}
        artistsList={artistsList}
      />
    </div>
  );
}

export default Artist;
