import { checkNumberWith10Digit, isValidEmail } from './Validations';

export const checkLoginData = data => {
  console.log();
  if (checkNumberWith10Digit(data.mobileNumber)) {
    return {
      mobileNumber: data.mobileNumber,
      password: data.password,
      platform: data.platform,
      email: data.email
    };
  }
  if (isValidEmail(data.username)) {
    return { email: data.username, password: data.password };
  }
  return null;
};
