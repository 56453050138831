export const dashboardList = [
  {
    photos: '',
    shop: 'ABC Barber Shop',
    aadhar: '1454577980676575',
    number: '9876543210',
    address: 'Vaishali Nagar, Jaipur',
    category: 'Male'
  },
  {
    photos: '',
    shop: 'ABC Barber Shop',
    aadhar: '1454577980676575',
    number: '9876543210',
    address: 'Vaishali Nagar, Jaipur',
    category: 'Male'
  },
  {
    photos: '',
    shop: 'ABC Barber Shop',
    aadhar: '1454577980676575',
    number: '9876543210',
    address: 'Vaishali Nagar, Jaipur',
    category: 'Male'
  },
  {
    photos: '',
    shop: 'ABC Barber Shop',
    aadhar: '1454577980676575',
    number: '9876543210',
    address: 'Vaishali Nagar, Jaipur',
    category: 'Male'
  },
  {
    photos: '',
    shop: 'ABC Barber Shop',
    aadhar: '1454577980676575',
    number: '9876543210',
    address: 'Vaishali Nagar, Jaipur',
    category: 'Male'
  },
  {
    photos: '',
    shop: 'ABC Barber Shop',
    aadhar: '1454577980676575',
    number: '9876543210',
    address: 'Vaishali Nagar, Jaipur',
    category: 'Male'
  },
  {
    photos: '',
    shop: 'ABC Barber Shop',
    aadhar: '1454577980676575',
    number: '9876543210',
    address: 'Vaishali Nagar, Jaipur',
    category: 'Male'
  }
];

export const dashboardColumnName = [
  {
    id: '1',
    name: 'Photos'
  },
  {
    id: '2',
    name: 'Shop Name'
  },
  {
    id: '3',
    name: 'Aadhar Card No.'
  },
  {
    id: '4',
    name: 'Mobile Number'
  },
  {
    id: '5',
    name: 'Address'
  },
  {
    id: '6',
    name: 'Category'
  },
  {
    id: '7',
    name: 'Actions'
  }
];

export const appointmentsList = [
  {
    id: '1',
    shop: 'ABC Barber Shop',
    customer: 'Ram Kumar',
    mobile: '9876543210',
    artist: 'abc@gmail.com',
    location: 'Vaishali Nagar, Jaipur'
  },
  {
    id: '2',
    shop: 'ABC Barber Shop',
    customer: 'Ram Kumar',
    mobile: '9876543210',
    artist: 'abc@gmail.com',
    location: 'Vaishali Nagar, Jaipur'
  },
  {
    id: '3',
    shop: 'ABC Barber Shop',
    customer: 'Ram Kumar',
    mobile: '9876543210',
    artist: 'abc@gmail.com',
    location: 'Vaishali Nagar, Jaipur'
  },
  {
    id: '4',
    shop: 'ABC Barber Shop',
    customer: 'Ram Kumar',
    mobile: '9876543210',
    artist: 'abc@gmail.com',
    location: 'Vaishali Nagar, Jaipur'
  },
  {
    id: '5',
    shop: 'ABC Barber Shop',
    customer: 'Ram Kumar',
    mobile: '9876543210',
    artist: 'abc@gmail.com',
    location: 'Vaishali Nagar, Jaipur'
  }
];

export const appointmentsColumnName = [
  {
    id: '1',
    name: 'Sr.No.'
  },
  {
    id: '2',
    name: 'Shop Name'
  },
  {
    id: '3',
    name: 'Customer Name'
  },
  {
    id: '4',
    name: 'Customer Mobile Number'
  },
  {
    id: '5',
    name: 'Artists Name'
  },
  {
    id: '6',
    name: 'Location'
  }
];
