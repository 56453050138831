const TogglerSVGIcon = () => {
  return (
    <svg
      width="29"
      height="24"
      viewBox="0 0 29 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.35 16.5H21.75V4.5H4.35V16.5ZM2.9 24C2.1025 24 1.42003 23.7065 0.8526 23.1195C0.2842 22.5315 0 21.825 0 21V3C0 2.175 0.2842 1.469 0.8526 0.882C1.42003 0.294 2.1025 0 2.9 0H26.1C26.8975 0 27.5805 0.294 28.1489 0.882C28.7163 1.469 29 2.175 29 3V21C29 21.825 28.7163 22.5315 28.1489 23.1195C27.5805 23.7065 26.8975 24 26.1 24H2.9ZM2.9 21H26.1V3H2.9V21Z"
        fill="black"
      />
    </svg>
  );
};

export default TogglerSVGIcon;
