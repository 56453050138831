import PackageCard from './PackageCard';
import styles from './shopServices.module.scss';
import { services } from '../../constants/ServicesConstants';
import AdminHeader from '../../layout/adminheader/AdminHeader';

const ShopServices = () => {
  return (
    <div className="middleData">
      <AdminHeader title="ABC Barber Shop" />
      <div className={styles.container}>
        <div className={styles.leftCard}>
          <span>Services</span>
          <div>
            {services.map((service, key) => {
              return (
                <div key={key} className={styles.service}>
                  {service}
                </div>
              );
            })}
          </div>
        </div>
        <div className={styles.rightCard}>
          <span>Packages</span>
          <div className={styles.allCards}>
            <PackageCard />
            <PackageCard />
            <PackageCard />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopServices;
