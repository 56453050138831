import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import {
  dashboardIcon,
  shopsIcon,
  customerIcon,
  packagesIcon,
  dashboardPackageIcon,
  dashboardOtherIcon
} from '../../assets';

function Menu() {
  const handleSetActiveLink = link => {
    setActiveLink(link);
  };

  const path = useLocation();

  const [activeLink, setActiveLink] = useState(path.pathname);

  return (
    <div className="sidebar">
      <span className="sidebarHeading">Menu</span>
      <ul className="sidebarList">
        <li
          className={`sidebarListItem ${
            activeLink === '/admin/dashboard' ? 'active' : ''
          }`}>
          <Link
            to="/admin/dashboard"
            onClick={() => handleSetActiveLink('/admin/dashboard')}>
            <span>
              <img src={dashboardIcon} alt="" />
            </span>
            <span>Dashboard</span>
          </Link>
        </li>

        <li
          className={`sidebarListItem ${
            activeLink === '/admin/shops' ? 'active' : ''
          }`}>
          <Link
            to="/admin/shops"
            onClick={() => handleSetActiveLink('/admin/shops')}>
            <span>
              <img src={shopsIcon} alt="" />
            </span>
            <span>Shop Owners</span>
          </Link>
        </li>

        <li
          className={`sidebarListItem ${
            activeLink === '/admin/customers' ? 'active' : ''
          }`}>
          <Link
            to="/admin/customers"
            onClick={() => handleSetActiveLink('/admin/customers')}>
            <span>
              <img src={customerIcon} alt="" />
            </span>
            <span>Customers</span>
          </Link>
        </li>

        <li
          className={`sidebarListItem ${
            activeLink === '/admin/artists' ? 'active' : ''
          }`}>
          <Link
            to="/admin/artists"
            onClick={() => handleSetActiveLink('/admin/artists')}>
            <span>
              <img src={packagesIcon} alt="" />
            </span>
            <span>Artists</span>
          </Link>
        </li>

        <li
          className={`sidebarListItem ${
            activeLink === '/admin/packages' ? 'active' : ''
          }`}>
          <Link
            to="/admin/packages"
            onClick={() => handleSetActiveLink('/admin/packages')}>
            <span>
              <img src={dashboardPackageIcon} alt="" />
            </span>
            <span>Packages</span>
          </Link>
        </li>

        <li
          className={`sidebarListItem ${
            activeLink === '/admin/others' ? 'active' : ''
          }`}>
          <Link
            to="/admin/others"
            onClick={() => handleSetActiveLink('/admin/others')}>
            <span>
              <img src={dashboardOtherIcon} alt="" />
            </span>
            <span>Others</span>
          </Link>
        </li>

        {/* <li
          className={`sidebarListItem ${
            activeLink === "/admin/packages" ? "active" : ""
          }`}
        >
          <Link
            to="/admin/packages"
            onClick={() => handleSetActiveLink("/admin/packages")}
          >
            <span>
              <img src={packagesIcon} alt="" />
            </span>
            <span>Packages</span>
          </Link>
        </li>

        <li
          className={`sidebarListItem ${
            activeLink === "/admin/offers" ? "active" : ""
          }`}
        >
          <Link
            to="/admin/offers"
            onClick={() => handleSetActiveLink("/admin/offers")}
          >
            <span>
              <img src={packagesIcon} alt="" />
            </span>
            <span>Offers</span>
          </Link>
        </li> */}
      </ul>
    </div>
  );
}

export default Menu;
