import styles from './ImageModal.module.scss';

const ImageModal = ({ selectedImage, setSelectedImage, images }) => {
  const clickHandler = (e, clickedImageUrl) => {
    e.stopPropagation();
    console.log(clickedImageUrl);
    setSelectedImage(clickedImageUrl);
  };

  return (
    <div className={styles.innerContainer} onClick={e => e.stopPropagation()}>
      <div className={styles.sideImage}>
        {images.map((img, index) => (
          <img
            key={index}
            src={img.imgUrl}
            onClick={e => clickHandler(e, img.imgUrl)}
            alt=""
          />
        ))}
      </div>
      <div className={styles.mainImage}>
        <img src={selectedImage} alt="" />
      </div>
    </div>
  );
};

export default ImageModal;
