import { useEffect } from 'react';
import './RightSideBar.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import ArtistsFilter from '../../filters/ArtistsFilter';
import CustomerFilter from '../../filters/CustomerFilter';
import ShopAppointmentFilter from '../../filters/ShopAppintmentFilter';
import ShopFilter from '../../filters/ShopFilter';
import Notification from '../../ReusableCoponents/Notification';
import ProfilePreview from '../../ReusableCoponents/ProfilePreview/ProfilePreview';
import { uiActions } from '../../store/ui/UiSlice';

function RightSideBar() {
  const dispatch = useDispatch();
  const location = useLocation();
  const path = useSelector(state => state.ui.rightSidebarPath);

  useEffect(() => {
    let tempPath = location.pathname;
    dispatch(uiActions.setRightSidebarHandler(tempPath));
  }, [location, dispatch]);

  return (
    <div className="dashboardRight">
      {path === '/admin/dashboard' && <Notification />}
      {path === 'profile' && <ProfilePreview />}
      {path === '/admin/customers' && <CustomerFilter />}
      {path === '/admin/shops' && <ShopFilter />}
      {path === '/admin/artists' && <ArtistsFilter />}
      {path === '/admin/shops/shopAppointments' && <ShopAppointmentFilter />}
    </div>
  );
}

export default RightSideBar;
