import { createSlice } from '@reduxjs/toolkit';
const profileSlice = createSlice({
  name: 'profile',
  initialState: {
    profileData: ''
  },
  reducers: {
    setProfileData(state, action) {
      state.profileData = action.payload;
    }
  }
});

export const profileActions = profileSlice.actions;

export default profileSlice;
