import { configureStore } from '@reduxjs/toolkit';

import authSlice from './auth/AuthSlice';
import profileSlice from './profile/ProfileSlice';
import shopSlice from './shop/ShopSlice';
import uiSlice from './ui/UiSlice';

export const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    profile: profileSlice.reducer,
    ui: uiSlice.reducer,
    shop: shopSlice.reducer
  }
});
