import instance from '../axiosInstance';

export const getShopOwnerNewProfiles = async verified => {
  try {
    const response = await instance.get(`/admin/ownerprofile/all/${verified}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching customers:', error);
    return error;
  }
};

export const approveShopOwnerProfile = async (ownerId, data) => {
  try {
    const response = await instance.patch(
      `/admin/ownerprofile/${ownerId}`,
      data
    );
    return response.data;
  } catch (error) {
    return error;
  }
};
