import PageHeader from '../layout/pageHeader/PageHeader';
import StarRating from '../ReusableCoponents/StarRating';
import './Table.scss';

function ArtistsTable({ artistsColumnName, artistsList, showHeader = true }) {
  return (
    <div className="DBdataGridBox">
      {showHeader && <PageHeader />}
      <table className="DBdataGrid">
        <thead>
          <tr>
            {artistsColumnName.map((col, key) => {
              return (
                <th key={key} id={col.id}>
                  {col.name}
                </th>
              );
            })}
          </tr>
        </thead>

        <tbody>
          {artistsList.map((list, key) => {
            return (
              <tr key={key} id={list.id}>
                <td>{list.id}</td>
                <td>{list.name}</td>
                <td>{list.work}</td>
                <td>{list.experience}</td>
                <td>{list.email}</td>
                <td>{list.phone}</td>
                <td>{list.address}</td>
                <td>{showHeader && <StarRating />}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default ArtistsTable;
