const LocationSVGIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.12"
        d="M7.00156 10.25C7.97656 8.3 10.9016 7.04141 10.9016 4.4C10.9016 2.24609 9.15547 0.5 7.00156 0.5C4.84765 0.5 3.10156 2.24609 3.10156 4.4C3.10156 7.04141 6.02656 8.3 7.00156 10.25Z"
        fill="#3B7358"
      />
      <path
        d="M2.45 8.48613C1.24661 9.017 0.5 9.75676 0.5 10.575C0.5 12.1904 3.41015 13.5 7 13.5C10.5899 13.5 13.5 12.1904 13.5 10.575C13.5 9.75676 12.7534 9.017 11.55 8.48613M10.9 4.4C10.9 7.04141 7.975 8.3 7 10.25C6.025 8.3 3.1 7.04141 3.1 4.4C3.1 2.24609 4.84609 0.5 7 0.5C9.15391 0.5 10.9 2.24609 10.9 4.4ZM7.65 4.4C7.65 4.75898 7.35898 5.05 7 5.05C6.64101 5.05 6.35 4.75898 6.35 4.4C6.35 4.04101 6.64101 3.75 7 3.75C7.35898 3.75 7.65 4.04101 7.65 4.4Z"
        stroke="#3B7358"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LocationSVGIcon;
