import { Outlet } from 'react-router-dom';

import Leftsidebar from '../../../layout/leftSideBar/LeftSideBar';
import RightSideBar from '../../../layout/rightSideBar/RightSideBar';

function MainPage() {
  return (
    <div className="App">
      <div className="main">
        <Leftsidebar />
        <div className="dashboardMid">
          <Outlet />
        </div>
        <RightSideBar />
      </div>
    </div>
  );
}

export default MainPage;
