import { searchIcon } from '../../assets/';
import styles from '../ReusableStyles.module.scss';

const SearchInputBox = () => {
  return (
    <div className={styles.searchInputContainer}>
      <img src={searchIcon} alt="" />
      <input type="text" placeholder="Search location" />
    </div>
  );
};

export default SearchInputBox;
