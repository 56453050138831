import './Branch.scss';
import { ProfileImg } from '../../../assets';
import AdminHeader from '../../../layout/adminheader/AdminHeader';

function Branch() {
  return (
    <div className="midData">
      <AdminHeader title="ABC Barber Shop" />
      <div className="branchOuter">
        <div className="branchBox">
          <div className="branchLeft">
            <img src={ProfileImg} alt="error" />
            <div className="branchName">
              <h5>Branch One</h5>
              <p>
                <span>
                  <i className="bi bi-geo-alt"></i>
                </span>
                <span>Jaipur Rajasthan</span>
              </p>
            </div>
            <ul className="branchFeatureList">
              <li className="branchFeature">
                <p>Artists</p>
                <h6>643</h6>
              </li>
              <li className="branchFeature">
                <p>Customers</p>
                <h6>76</h6>
              </li>
            </ul>
            <div className="branchBntBox">
              <button className="adminSecondryBtn">
                <span>
                  <i className="bi bi-geo-alt"></i>
                </span>
                <span>Location</span>
              </button>
            </div>
          </div>
          <div className="branchRight">
            <ul className="branchDetailList">
              <li className="branchDetailListItem">
                <span className="branchDetailIcon">
                  <i className="bi bi-person"></i>
                </span>
                <span className="branchDetailText">
                  <p>Owner name</p>
                  <h6>Ram Kumar</h6>
                </span>
              </li>
              <li className="branchDetailListItem">
                <span className="branchDetailIcon">
                  <i className="bi bi-envelope"></i>
                </span>
                <span className="branchDetailText">
                  <p>Email</p>
                  <h6>abc@gmail.com</h6>
                </span>
              </li>
              <li className="branchDetailListItem">
                <span className="branchDetailIcon">
                  <i className="bi bi-telephone"></i>
                </span>
                <span className="branchDetailText">
                  <p>Phone number</p>
                  <h6>+91 9876543210</h6>
                </span>
              </li>
              <li className="branchDetailListItem">
                <span className="branchDetailIcon">
                  <i className="bi bi-grid"></i>
                </span>
                <span className="branchDetailText">
                  <p>Category</p>
                  <h6>Male</h6>
                </span>
              </li>
              <li className="branchDetailListItem">
                <span className="branchDetailIcon">
                  <i className="bi bi-map"></i>
                </span>
                <span className="branchDetailText">
                  <p>Pincode</p>
                  <h6>302012</h6>
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div className="branchBox">
          <div className="branchLeft">
            <img src={ProfileImg} alt="error" />
            <div className="branchName">
              <h5>Branch One</h5>
              <p>
                <span>
                  <i className="bi bi-geo-alt"></i>
                </span>
                <span>Jaipur Rajasthan</span>
              </p>
            </div>
            <ul className="branchFeatureList">
              <li className="branchFeature">
                <p>Artists</p>
                <h6>643</h6>
              </li>
              <li className="branchFeature">
                <p>Customers</p>
                <h6>76</h6>
              </li>
            </ul>
            <div className="branchBntBox">
              <button className="adminSecondryBtn">
                <span>
                  <i className="bi bi-geo-alt"></i>
                </span>
                <span>Location</span>
              </button>
            </div>
          </div>
          <div className="branchRight">
            <ul className="branchDetailList">
              <li className="branchDetailListItem">
                <span className="branchDetailIcon">
                  <i className="bi bi-person"></i>
                </span>
                <span className="branchDetailText">
                  <p>Owner name</p>
                  <h6>Ram Kumar</h6>
                </span>
              </li>
              <li className="branchDetailListItem">
                <span className="branchDetailIcon">
                  <i className="bi bi-envelope"></i>
                </span>
                <span className="branchDetailText">
                  <p>Email</p>
                  <h6>abc@gmail.com</h6>
                </span>
              </li>
              <li className="branchDetailListItem">
                <span className="branchDetailIcon">
                  <i className="bi bi-telephone"></i>
                </span>
                <span className="branchDetailText">
                  <p>Phone number</p>
                  <h6>+91 9876543210</h6>
                </span>
              </li>
              <li className="branchDetailListItem">
                <span className="branchDetailIcon">
                  <i className="bi bi-grid"></i>
                </span>
                <span className="branchDetailText">
                  <p>Category</p>
                  <h6>Male</h6>
                </span>
              </li>
              <li className="branchDetailListItem">
                <span className="branchDetailIcon">
                  <i className="bi bi-map"></i>
                </span>
                <span className="branchDetailText">
                  <p>Pincode</p>
                  <h6>302012</h6>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Branch;
