import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Index from './components/router/index.js';
import './components/styles/style.css';
import { authActions } from './components/store/auth/AuthSlice.js';
import { getTokenFromLocalStorage } from './components/utils/customHooks.js';

function App() {
  // testing bidy=u
  const token = useSelector(state => state.auth.token);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!token) {
      let getToken = getTokenFromLocalStorage('token');
      let getRole = getTokenFromLocalStorage('role');
      let getAuthId = getTokenFromLocalStorage('authId');
      // console.log("getToken", getToken);
      dispatch(authActions.setTokenHandler(getToken));
      dispatch(authActions.setRoleHandler(getRole));
      dispatch(authActions.setAuthId(getAuthId));
    }
  });

  return (
    // <ShopModal />
    <Index />
    //  <h1>Piyush</h1>
  );
}

export default App;
