import { useState } from 'react';

import styles from './ProfilePreview.module.scss';

const Address = ({ address, setEditedProfileData }) => {
  const [editedAddress, setEditedAddress] = useState(address || {});

  const handleAddressChange = event => {
    const { name, value } = event.target;
    const updatedAddress = {
      ...editedAddress,
      [name]: value
    };
    // setEditedAddress((prevAddress)=>({
    //     ...prevAddress,
    //     [name]: value
    // }))
    setEditedAddress(updatedAddress);

    setEditedProfileData(prevData => ({
      ...prevData,
      address: updatedAddress
    }));
  };

  return (
    <div className={styles.address}>
      <input
        type="text"
        placeholder="street"
        value={editedAddress?.street || ''}
        onChange={handleAddressChange}
        name="street"
      />

      <input
        type="text"
        placeholder="city"
        value={editedAddress?.city || ''}
        onChange={handleAddressChange}
        name="city"
      />

      <input
        type="text"
        placeholder="state"
        value={editedAddress?.state || ''}
        onChange={handleAddressChange}
        name="state"
      />

      <input
        type="text"
        placeholder="country"
        value={editedAddress?.country || ''}
        onChange={handleAddressChange}
        name="country"
      />

      <input
        type="text"
        placeholder="pincode"
        value={editedAddress?.pincode || ''}
        onChange={handleAddressChange}
        name="pincode"
      />
    </div>
  );
};

export default Address;
