import styles from './ShopDashboardPage.module.scss';
import { starIcon, starImage } from '../../assets';
import RatingBarChart from '../../ReusableCoponents/RatingBarChart/RatingBarChart';

const ShopRating = () => {
  return (
    <div className={styles.container}>
      <div className={styles.numberRating}>
        <h1>
          4.5 <img src={starImage} alt="" />
        </h1>
        <button>See all 63 reviews</button>
      </div>
      <div className={styles.ratingGroup}>
        <div className={styles.starIcon}>
          <div className={styles.singleStarGroup}>
            <span>5</span>
            <img src={starIcon} alt="" />
          </div>
          <div className={styles.singleStarGroup}>
            <span>4</span>
            <img src={starIcon} alt="" />
          </div>
          <div className={styles.singleStarGroup}>
            <span>3</span>
            <img src={starIcon} alt="" />
          </div>
          <div className={styles.singleStarGroup}>
            <span>2</span>
            <img src={starIcon} alt="" />
          </div>
          <div className={styles.singleStarGroup}>
            <span>1</span>
            <img src={starIcon} alt="" />
          </div>
        </div>
        <div className={styles.ratingBarContainer}>
          <RatingBarChart />
        </div>
      </div>
    </div>
  );
};

export default ShopRating;
