const CallSVGIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.35052 4.06164C8.98318 4.18507 9.56462 4.49449 10.0204 4.95029C10.4762 5.40608 10.7856 5.98752 10.9091 6.62019M8.35052 1.4707C9.66495 1.61673 10.8907 2.20535 11.8264 3.13992C12.7622 4.07449 13.3523 5.29946 13.5 6.61371M5.87422 9.15481C5.09591 8.37651 4.48135 7.49647 4.03053 6.55751C3.99175 6.47674 3.97236 6.43636 3.95747 6.38526C3.90453 6.20366 3.94256 5.98067 4.05268 5.82688C4.08367 5.78361 4.12069 5.74658 4.19473 5.67254C4.42118 5.44609 4.53441 5.33286 4.60844 5.21901C4.88761 4.78963 4.88761 4.23609 4.60844 3.80671C4.53441 3.69285 4.42118 3.57963 4.19473 3.35318L4.06851 3.22695C3.72427 2.88272 3.55216 2.7106 3.36731 2.61711C2.99968 2.43116 2.56553 2.43116 2.1979 2.61711C2.01305 2.7106 1.84093 2.88272 1.4967 3.22695L1.39459 3.32906C1.05154 3.67211 0.880009 3.84364 0.749007 4.07684C0.603642 4.33562 0.499122 4.73753 0.500006 5.03434C0.500801 5.30182 0.552687 5.48462 0.656459 5.85023C1.21414 7.81507 2.26636 9.66911 3.81314 11.2159C5.35992 12.7627 7.21396 13.8149 9.1788 14.3726C9.54441 14.4763 9.72721 14.5282 9.99469 14.529C10.2915 14.5299 10.6934 14.4254 10.9522 14.28C11.1854 14.149 11.3569 13.9775 11.7 13.6344L11.8021 13.5323C12.1463 13.1881 12.3184 13.016 12.4119 12.8311C12.5979 12.4635 12.5979 12.0294 12.4119 11.6617C12.3184 11.4769 12.1463 11.3048 11.8021 10.9605L11.6759 10.8343C11.4494 10.6078 11.3362 10.4946 11.2223 10.4206C10.7929 10.1414 10.2394 10.1414 9.81003 10.4206C9.69617 10.4946 9.58294 10.6078 9.35649 10.8343C9.28245 10.9083 9.24543 10.9454 9.20215 10.9764C9.04836 11.0865 8.82537 11.1245 8.64377 11.0716C8.59267 11.0567 8.55229 11.0373 8.47153 10.9985C7.53256 10.5477 6.65252 9.93312 5.87422 9.15481Z"
        stroke="#3B7358"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CallSVGIcon;
