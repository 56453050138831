import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getShopOwnerApprovedProfiles } from '../../../apiServices/Shop/ShopApiServices';
import { shopOwnerColumnName } from '../../../constants/ShopOwnerConstants';
import AdminHeader from '../../../layout/adminheader/AdminHeader';
import { shopActions } from '../../../store/shop/ShopSlice';
import ShopOwnerTable from '../../../tables/ShopOwnerTable';

const Shops = () => {
  const dispatch = useDispatch();
  const approvedProfilesData = useSelector(
    state => state.shop.approvedOwnersProfile
  );
  // const [approvedProfilesData, setApprovedProfilesData] = useState([])

  const getApprovedProfilesData = useCallback(() => {
    getShopOwnerApprovedProfiles().then(res => {
      if (res?.data.length > 0)
        dispatch(shopActions.setApprovedOwnersProfile(res?.data));
      // console.log("Approved Profile Data",res?.data)
    });
  }, [dispatch]);

  useEffect(() => {
    getApprovedProfilesData();
  }, [getApprovedProfilesData]);

  // console.log("Approved Profiles are", approvedProfilesData)

  return (
    <div className="middleData">
      <AdminHeader title="Shop Owners" />
      <ShopOwnerTable
        shopOwnerColumnName={shopOwnerColumnName}
        approvedProfilesData={approvedProfilesData}
      />
    </div>
  );
};

export default Shops;
