import styles from './ShopReview.module.scss';
import { reviewProfileImage } from '../../assets';
import StarRating from '../../ReusableCoponents/StarRating';

const ReviewCard = () => {
  return (
    <div className={styles.reviewCardContainer}>
      <div className={styles.profile}>
        <img src={reviewProfileImage} alt="" />
        <div className={styles.details}>
          <h6>Illiyana</h6>
          <h5>Jaipur, Rajasthan</h5>
          <StarRating />
        </div>
      </div>
      <span className={styles.review}>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat, eius?
        Accusantium earum voluptate quas modi veniam nam provident expedita{' '}
      </span>
    </div>
  );
};

export default ReviewCard;
